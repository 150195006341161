import { Button, InputAdornment, TextField, Typography, CircularProgress, } from '@material-ui/core';
import { useStyles } from '../style'
import { RemoveRedEye } from '@material-ui/icons';
import { AxiosError, AxiosResponse } from 'axios';
import api from '../../../../apis/dreamrichapi';
import { AuthResponse, IConfirmPassword, NewPasswordChallengeResponse } from '../../types';
import { IAuthFormsProps } from '../interface';
import React from 'react';

export const ChangePasswordForm: React.FC<IAuthFormsProps> = props => {
    const classes = useStyles();
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordValidationError, setPasswordValidationError] = React.useState('');

    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [confirmValidationError, setConfirmValidationError] = React.useState('');

    const passwordRe = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const [formError, setFormError] = React.useState('');

    const changePassword = (event: React.FormEvent) => {
        event.preventDefault();
        if (!(passwordValidationError && confirmValidationError)) {
            api.post<NewPasswordChallengeResponse, AxiosResponse<AuthResponse | null>>('/respondAuthChallenge', {
                challengeResponse: {
                    USERNAME: props.authState.authResponse.ChallengeParameters.USER_ID_FOR_SRP,
                    NEW_PASSWORD: confirmPassword,
                },
                challengeName: props.authState.authResponse.ChallengeName,
                session: props.authState.authResponse.Session,
            })
                .then(res => {
                    if (res.data) {
                        props.dispatchResponse(res.data);
                    } else {
                        props.doneAuth();
                    }
                })
                .catch(e => setFormError('Não foi possível alterar a senha'));
        }
    };

    const onPasswordBlur = () => {
        if (!passwordRe.test(password) && password) {
            setPasswordValidationError(
                'Senhas devem conter 8 caracteres com pelo menos um número, uma letra e um caractere especial'
            );
        } else {
            setPasswordValidationError('');
        }
    };

    const onConfirmBlur = () => {
        if (password !== confirmPassword) {
            setConfirmValidationError('Senhas não coincidem');
            return;
        } else {
            setConfirmValidationError('');
        }
    };

    return (
        <>
            <form className={classes.form} onSubmit={changePassword}>
                <Typography style={{color: 'white'}}>Olá, precisamos que você defina sua nova senha!</Typography>
                <TextField
                    className={`${classes.label} mt-3 mb-3`}
                    variant="filled"
                    color="primary"
                    value={password}
                    label={'Senha'}
                    error={passwordValidationError ? true : false}
                    helperText={passwordValidationError}
                    type={showPassword ? 'text' : 'password'}
                    onChange={event => setPassword(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* @ts-ignore */}
                                <RemoveRedEye className={classes.eye} onClick={() => setShowPassword(show => !show)} />
                            </InputAdornment>
                        ),
                    }}
                    onBlur={onPasswordBlur}
                />
                <TextField
                    className={`${classes.label} mt-3 mb-3`}
                    variant="filled"
                    color="primary"
                    label="Confirme sua senha"
                    value={confirmPassword}
                    error={confirmValidationError ? true : false}
                    helperText={confirmValidationError}
                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={event => setConfirmPassword(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* @ts-ignore */}
                                <RemoveRedEye
                                    className={classes.eye}
                                    onClick={() => setShowConfirmPassword(show => !show)}
                                />
                            </InputAdornment>
                        ),
                    }}
                    onBlur={onConfirmBlur}
                />
                <Typography className={classes.formItem} color="error" align="center">
                    {formError}
                </Typography>

                <Button variant="contained" type="submit" className={classes.submitbutton}>
                    Mudar minha senha
                </Button>
            </form>
        </>
    );
};

export const ForgotPasswordForm: React.FC<IAuthFormsProps> = props => {
    const classes = useStyles();
    const [username, setUsername] = React.useState('');
    const [err, setErr] = React.useState('');

    const forgotPassword = (event: React.FormEvent) => {
        event.preventDefault();
        api.get(`/forgotPassword?user=${username}`)
            .then(() => {
                props.dispatchUsername({ ...new AuthResponse(), ChallengeName: 'CONFIRM_PASSWORD' }, username);
            })
            .catch((e: AxiosError<string>) => {
                if (e.response?.data) {
                    if (e.response.data === 'Attempt limit exceeded, please try after some time.') {
                        setErr('Limite máximo de tentativas excedido, tente novamente em alguns minutos');
                    }
                }
            });
    };

    return (
        <>
            {/* Usuário para trocar senha */}
            <form onSubmit={forgotPassword} className={classes.form}>
                <TextField
                    className={`${classes.label} mt-3 mb-3`}
                    variant="filled"
                    value={username}
                    onChange={event => setUsername(event.target.value)}
                    label="Email"
                />
                <Typography className={classes.formItem} color="error" align="center">
                    {err}
                </Typography>
                <div style={{ display: 'flex' }}>
                    {/* @ts-ignore */}
                    <Button variant="contained" onClick={() => props.goBack(props)} className={classes.submitbutton}>
                        Voltar
                    </Button>
                    <Button variant="contained" type="submit" className={classes.submitbutton}>
                        Enviar
                    </Button>
                </div>
            </form>
        </>
    );
};

export const ForceChangePassword: React.FC<IAuthFormsProps> = props => {
    const classes = useStyles();
    const [verificationCode, setVerificationCode] = React.useState<string | null>(null);
    const [isPassword, setIsPassword] = React.useState<boolean>(true);
    const [password, setPassword] = React.useState<string>('');
    const [pwdHelper, setPwdHelper] = React.useState<string>('');
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [err, setErr] = React.useState<string | null>(null);
    const [showAlert, setShowAlert] = React.useState<boolean>(false);
    const email = props.authState.authResponse?.ChallengeAttributes?.email;
    const username = props.authState.authResponse?.ChallengeAttributes?.username;
    const pwdRegex = /^[A-Za-z\d@$!%*#?&]{8,}$/;

    React.useEffect(() => {
        setIsSubmitting(true);
        api.get(`/forgotPassword?user=${username}`)
            .then(res => {
                setShowAlert(false);
                setIsSubmitting(false);
            })
            .catch((e: AxiosError<string>) => {
                setShowAlert(false);
                setIsSubmitting(false);
                if (e.response?.data) {
                    if (e.response.data === 'Attempt limit exceeded, please try after some time.') {
                        setErr('Limite máximo de tentativas excedido, tente novamente em alguns minutos');
                    }
                }
            });
    }, []);

    const changePassword = (event: React.FormEvent) => {
        event.preventDefault();

        if (password.length < 8) {
            setPwdHelper('Senha muito curta');
            return;
        }

        if (!pwdRegex.test(password)) {
            setPwdHelper('Deve conter um número e um caractere especial');
            return;
        }
        setIsSubmitting(true);

        api.post<IConfirmPassword, null>('/confirmPassword', {
            password,
            verificationCode,
            username,
        })
            .then(() => {
                props.dispatchResponse({ ...new AuthResponse(), ChallengeName: 'LOGIN' });
                setIsSubmitting(false);
                setErr('');
            })
            .catch((error: AxiosError<string>) => {
                setIsSubmitting(false);
                console.debug(error);
                if (error.response?.data) {
                    setErr(error.response.data);
                }
            });
    };

    return (
        <>
            {!showAlert && (
                <>
                    <Typography align="left" style={{ fontSize: '.9rem' }}>
                        Sua senha temporária expirou. O código de confirmação foi enviado para o seu e-mail
                    </Typography>

                    <form onSubmit={changePassword} className={classes.form}>
                        <TextField
                            className={`${classes.formItem} mt-3`}
                            variant="outlined"
                            color="primary"
                            value={email}
                            disabled
                            label="E-mail"
                        />
                        <TextField
                            autoComplete="off"
                            className={`${classes.formItem} mt-3`}
                            variant="outlined"
                            color="primary"
                            value={verificationCode}
                            onChange={event => setVerificationCode(event.target.value)}
                            inputProps={{
                                autoComplete: '',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            label="Código de verificação"
                        />
                        <TextField
                            className={`${classes.formItem} mt-3`}
                            variant="outlined"
                            color="primary"
                            label="Nova senha"
                            value={password}
                            type={isPassword ? 'password' : 'text'}
                            onChange={event => setPassword(event.target.value)}
                            error={pwdHelper.length > 0}
                            helperText={pwdHelper}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {/* @ts-ignore */}
                                        <RemoveRedEye
                                            className={classes.eye}
                                            onClick={() => setIsPassword(!isPassword)}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Typography className={classes.formItem} color="error" noWrap align="center">
                            {err}
                        </Typography>
                        <Button variant="contained" type="submit" className={classes.submitbutton}>
                            {isSubmitting && <CircularProgress />}
                            {!isSubmitting && 'Enviar'}
                        </Button>
                    </form>
                </>
            )}
            {showAlert && (
                <>
                    {isSubmitting && <CircularProgress />}
                    {!isSubmitting && <Typography>{err}</Typography>}
                </>
            )}
        </>
    );
};

export const ConfirmPasswordForm: React.FC<IAuthFormsProps> = props => {
    const classes = useStyles();

    const [verificationCode, setVerificationCode] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isPassword, setIsPassword] = React.useState(true);
    const [err, setErr] = React.useState('');
    const [pwdHelper, setPwdHelper] = React.useState('');

    const pwdRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const confirmPassword = (e: React.FormEvent) => {
        e.preventDefault();

        if (password.length < 8) {
            setPwdHelper('Senha muito curta');
            return;
        }

        if (!pwdRegex.test(password)) {
            setPwdHelper('Deve conter um número e um caractere especial');
            return;
        }

        setPwdHelper('');

        api.post<IConfirmPassword, null>('/confirmPassword', {
            password,
            verificationCode,
            username: props.authState.username,
        })
            .then(() => {
                props.dispatchResponse({ ...new AuthResponse(), ChallengeName: 'LOGIN' });
                setErr('');
            })
            .catch((e: AxiosError<string>) => {
                console.debug(err);
                if (e.response?.data) {
                    setErr(e.response.data);
                }
            });
    };

    return (
        <>
            <form onSubmit={confirmPassword} className={classes.form}>
                <TextField
                    className={`${classes.formItem} mt-3`}
                    variant="filled"
                    color="primary"
                    value={props.authState.username}
                    disabled
                    label="Usuário"
                />
                <TextField
                    autoComplete="off"
                    className={`${classes.formItem} mt-3`}
                    variant="filled"
                    color="primary"
                    value={verificationCode}
                    onChange={event => setVerificationCode(event.target.value)}
                    inputProps={{
                        autoComplete: '',
                        form: {
                            autocomplete: 'off',
                        },
                    }}
                    label="Código de verificação"
                />
                <TextField
                    className={`${classes.formItem} mt-3`}
                    variant="filled"
                    color="primary"
                    label="Nova senha"
                    value={password}
                    type={isPassword ? 'password' : 'text'}
                    onChange={event => setPassword(event.target.value)}
                    error={pwdHelper.length > 0}
                    helperText={pwdHelper}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* @ts-ignore */}
                                <RemoveRedEye className={classes.eye} onClick={() => setIsPassword(isPwd => !isPwd)} />
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography className={classes.formItem} color="error" noWrap align="center">
                    {err}
                </Typography>
                {/* TODO ADD LOAD*/}
                <div className="d-flex justify-content-center mt-1">
                    <Button variant="contained" type="submit" className={`${classes.submitbutton} mt-3`}>
                        Enviar
                    </Button>
                </div>

            </form>
        </>
    );
};