import React from 'react';
import { makeStyles, Theme, createStyles, Typography, Chip, Button } from '@material-ui/core';
import { IPersonalData } from '../types';
import PersonalData from '../personalData/personalData';
import { validateCPF } from '../../../../utils/validateCPF';
import { grey } from '@material-ui/core/colors';
import { useStyles } from './style';

interface IPersonalDataProps {
    personalInfo: IPersonalData;
    setPersonalInfo: (data: IPersonalData) => void;
    nextStep: () => void;
    goBack: () => void;
}

const PartnerInfo: React.FC<IPersonalDataProps> = ({ personalInfo, setPersonalInfo, goBack, nextStep }) => {
    const classes = useStyles();
    const [cpfErr, setCpfErr] = React.useState(false);
    const [btnDisabled, setBtnDisabled] = React.useState(false);
    const validate = () => {
        if (personalInfo.nationality === 'Brasil') {
            const validCPF = validateCPF(personalInfo.cpf.replace(/\./g, '').replace('-', ''));
            setCpfErr(!validCPF);
            if (!validCPF) {
                return;
            }
        } else if (personalInfo?.rne?.trim() === '') {
            setCpfErr(true);
            return;
        }

        let invalideForm =
            personalInfo.fullName?.trim() === '' ||
            personalInfo.dateOfBirth?.toDateString() === new Date().toDateString();

        if (invalideForm) {
            setCpfErr(true);
            return;
        }
        setBtnDisabled(true);
        nextStep();
    };

    return (
        <div className={classes.content}>
            <Typography align="center" variant="h5" className={classes.header}>
                Dados do cônjuge
            </Typography>
            <Typography align="center" variant="body1" className='mb-5'>
                Colete os dados básicos do cônjuge e preencha aqui.
            </Typography>
            <PersonalData
                cpfErr={cpfErr}
                personalInfo={personalInfo}
                setPersonalInfo={setPersonalInfo}
                married
                isPartnerData={true}
            />
            <div className={`${classes.formControllers} container`}>
                {/* @ts-ignore */}
                <Button
                    variant="contained"
                    className={classes.outButton}
                    size="medium"
                    color="default"
                    disableElevation
                    onClick={goBack}
                >
                    Voltar
                </Button>
                {/* @ts-ignore */}
                <Chip
                    disabled={btnDisabled}
                    className={classes.chip}
                    size="medium"
                    label="Próximo"
                    clickable
                    onClick={validate}
                />
            </div>
        </div>
    );
};
export default PartnerInfo;
